@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "CircularStd";
    font-weight: 300;
    src: url(../assets/fonts/CircularStd-light.otf);
  }
  @font-face {
    font-family: "CircularStd";
    font-weight: 400;
    src: url(../assets/fonts/CircularStd-book.otf);
  }
  @font-face {
    font-family: "CircularStd";
    font-weight: 500;
    src: url(../assets/fonts/CircularStd-medium.otf);
  }
  @font-face {
    font-family: "CircularStd";
    font-weight: 600;
    src: url(../assets/fonts/CircularStd-medium.otf);
  }
  @font-face {
    font-family: "CircularStd";
    font-weight: bold;
    src: url(../assets/fonts/CircularStd-bold.otf);
  }
  @font-face {
    font-family: "CircularStd";
    font-weight: 900;
    src: url(../assets/fonts/CircularStd-black.otf);
  }

  @font-face {
    font-family: "Moderat";
    font-weight: 700;
    src: url(../assets/fonts/Moderat-bold.ttf);
  }

  html {
    font-family: CircularStd, system-ui, sans-serif;
    /* color: #4f4f4f; */
  }
}

.font-moderat {
  font-family: Moderat, system-ui, sans-serif;
}

.rotate-scale-svg {
  animation: rotateAnimation 3s ease-in-out infinite alternate;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg) scale(0.9);
  }
  100% {
    transform: rotate(360deg) scale(1.1);
  }
}

.glow {
  animation: scale-in-out 0.75s ease-in-out infinite alternate;
}

@keyframes scale-in-out {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(2.1);
  }
}

.loading-screen {
  animation: bgColorChange 1.25s ease-in-out infinite alternate;
}

@keyframes bgColorChange {
  0% {
    background-color: #fadbf2;
  }
  100% {
    background-color: #ffebfa;
  }
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 991px) {
  .service_four {
    margin-top: 50px;
  }
}

.page-content {
  line-height: 28px;
}
.page-content h3 {
  color: #888;
  font-size: 20px;
  font-weight: 600;
}

.page-content p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0;
}
